import { i18n } from "@/locales/i18n";

export default ({
  handle_error(err, that) {
    if (err.response) {
      console.log(err.response.data);
      console.log(err.response.status);
      if (err.response.status == 500) {
        alert(i18n.t('alert.serverfail'));
      } else if (err.response.status == 401) {
        alert(i18n.t('alert.login.loginfail'));
      } else if (err.response.status == 404) {
        alert(err.response.data.detail);
      } else if (err.response.status == 403) {
        //var path = window.location.pathname;
        //if (path != "/login") {
//          alert("Forbidden");
          that.$router.push("/");
        //}
      } else if (err.response.status == 406) {
        alert(err.response.data.detail);
      } else if (err.response.status == 410) {
        alert("No Resource");
      } else if (err.response.status == 422) {
        alert("Mandatory Missing");
      }
    } else {
      return;
    }
  },
})
